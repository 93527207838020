import React, { useCallback} from "react";
import { Lead } from "atoms/lead";
import { ButtonAmSpot, ButtonNaSpot } from "components/Buttons/Button.Styled";
import { FlexContainer } from "components/Flex/Flex.Styles";
import { TextStyled } from "components/Text/Text.Styles";
import { getWeekdayName } from "utils/getWeekday";
import { LeadBillingInfo, LeadPlanInfo, LeadSchedulingInfo } from "../LeadManagement";
import { slots } from "../../../constants";
import { Slot } from "../LeadMainInfo/LeadInfoForm";

interface ScheduleAppointmentProps {
    date: {
        additions?: any[];
        default?: boolean; 
        value: any; 
        label: string,
    }
    eventId?: string;
    setData: React.Dispatch<React.SetStateAction<Lead & LeadPlanInfo & LeadBillingInfo & LeadSchedulingInfo>>;
    data: any;
    scheduledDate?: any;
    scheduledSlot?: any;
    setScheduledDate?: React.Dispatch<React.SetStateAction<any>>;
    setScheduledSlot?: React.Dispatch<React.SetStateAction<any>>;
    onSlotSelect?: (isSelected: boolean, date: any, slot: Slot, addition: any) => void;
    selectedDate?: any;
    selectedSlot?: Slot;
}

export const ScheduleAppointment = (props: ScheduleAppointmentProps) => {
    const isSlotAvailable = useCallback(() => {
        return props.date?.additions && props.date?.additions.length > 0;
    }, [props.date]);

    const getAvailableAddition = useCallback(() => {
        if(props.date?.additions) {
            return props.date.additions[0];
        }
    }, [props.date]);

    const handleSlotClick = useCallback((date: any, slot: Slot) => {
        const addition = getAvailableAddition();
        props.onSlotSelect && props.onSlotSelect(true, date, slot, addition);
    }, [getAvailableAddition, props.onSlotSelect]);

    return (
        <div key={props.date?.label} style={{marginRight: "10px"}} >
            <div className="spot-card">
            {props.date?.additions && (
                <>  
                    <FlexContainer column={true} gap={1}>
                        <TextStyled fontSize="14">{getWeekdayName(props.date.value)} {props.date.value.substr(-2)}</TextStyled>
                    </FlexContainer>
                    
                    <FlexContainer column={true} gap={5}>
                        {slots.map((item: any, index: number) => (
                            isSlotAvailable() ? (
                                <ButtonAmSpot 
                                    key={index}
                                    onClick={() => handleSlotClick(props.date, item.slot)} 
                                    isSelected={props.selectedDate?.value === props.date.value && props.selectedSlot === item.slot}
                                >
                                    {item.label}
                                </ButtonAmSpot>
                            ) : (
                                <ButtonNaSpot key={index} disabled>{item.label}</ButtonNaSpot>
                            )
                        ))}
                    </FlexContainer>
                </>
            )}
            </div>
        </div>
    );
};