import React, {useEffect, useState} from "react";
import { useRecoilValue } from "recoil";

import { plansAtom } from "./../../../atoms/plans";
import { FormField } from "../Input/Input.Styles";
import Select from "../Select/Select";
import { AddonsStyles } from "./Addons.Styles";
import { TextStyled } from "../../Text/Text.Styles";
import { Tooltip } from "react-tooltip";

export interface OptionType {
  value: any;
  label: string;
  isRecurring: boolean;
  data: {
    initial: string;
    recurring: string;
    initialMin: string;
    initialMax: string;
    recurringMin: string;
    recurringMax: string;
    selectedInitialAddons: any;
    selectedRecurringAddons: any;
  }  
}

interface AddonsProps {
  planId: number;
  monthly?: boolean;
  isIniAptCmplted?: boolean;
  data?: any;
  setInitialTotal?: any
  setRecurringTotal?: any;
  setData: any;
  setAddonsErrors: any;
}

const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const canAddonPriceBeEdited = (floor: string, ceiling: string): boolean => {
  return parseFloat(ceiling) > parseFloat(floor) && parseFloat(ceiling) > 0;
};

const Addons = (props: AddonsProps): React.JSX.Element => {
  const plans = useRecoilValue(plansAtom);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState<OptionType[]>([]);  
  const [selections, setSelections] = useState<OptionType[]>([]); 
  const [initialAddons, setInitialAddons] = useState<Number>(0);
  const [recurringAddons, setRecurringAddons] = useState<Number>(0);
  const [errors, setErrors] = useState<any>({});
  const [pr_recurringAddons, setPrRecurringAddons] = useState<any[]>([]);
  const [pr_initialAddons, setPrInitialAddons] = useState<any[]>([]);
  const [tooltipVisible, setTooltipVisible] = useState<{ [key: string]: boolean }>({});
  const [rawInputValues, setRawInputValues] = useState<{ [key: string]: string }>({});
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    const initialAddons = props.data.selectedInitialAddons
        ? props.data.selectedInitialAddons.map((item: any) => item.product_id).join(",")
        : "";

    const recurringAddons = props.data.selectedRecurringAddons
      ? props.data.selectedRecurringAddons.map((item: any) => item.product_id).join(",")
      : "";
    
      let rawInputValues: { [key: string]: string } = {};

        props.data.selectedInitialAddons?.forEach((addon: { product_id: number, amount: number }) => {
          rawInputValues[`initial-${addon.product_id}`] = addon.amount.toString();
        });
        props.data.selectedRecurringAddons?.forEach((addon: { product_id: number, amount: number }) => {
          rawInputValues[`recurring-${addon.product_id}`] = addon.amount.toString();
        });

        setRawInputValues(rawInputValues);

      const addons = [initialAddons, recurringAddons].filter(Boolean).join(",");
      setValue(addons);
  }, []);

  useEffect(() => {
    if (props.data && (props.data.pr_initial_addons || props.data.pr_recurring_addons)) {
      
      setPrRecurringAddons(props.data.pr_recurring_addons || [] );
      setPrInitialAddons(props.data.pr_initial_addons || []);
    }
  }, [props.data]);
  
  useEffect(() => {    
    const ids = value.split(',');
    const newSelections = [];
    let initial_addons: any[] = [];
    let recurring_addons: any[] = [];
    for(const thisId of ids) {
      for (const option of options) {        
        if(option.value === thisId) {
          // Preserve user-modified values using option.value as key
          const initialValue = rawInputValues[`initial-${option.value}`] ?? 
            (pr_initialAddons.some((addon: any) => addon.product_id === Number(option.value)) ? 
              pr_initialAddons.find((addon: any) => addon.product_id === Number(option.value)).amount : 
              (pr_recurringAddons.some((addon: any) => addon.product_id === Number(option.value)) ? 0 : option.data.initial));
          const recurringValue = rawInputValues[`recurring-${option.value}`] ?? 
            (pr_recurringAddons.some((addon: any) => addon.product_id === Number(option.value)) ? 
              pr_recurringAddons.find((addon: any) => addon.product_id === Number(option.value)).amount : 
              (pr_initialAddons.some((addon: any) => addon.product_id === Number(option.value)) ? 0 : option.data.recurring));

          const updatedOption = {
            ...option,
            data: {
              ...option.data,
              initial: initialValue,
              recurring: recurringValue,
              selectedInitialAddons: {
                ...option.data.selectedInitialAddons,
                amount: initialValue,
              },
              selectedRecurringAddons: {
                ...option.data.selectedRecurringAddons,
                amount: recurringValue,
              }
            }
          };

          newSelections.push(updatedOption);
          option.data.selectedInitialAddons && initial_addons.push(updatedOption.data.selectedInitialAddons);
          option.data.selectedRecurringAddons && recurring_addons.push(updatedOption.data.selectedRecurringAddons);
          break;
        }
      }
    }

    let uniqueNewSelections = newSelections.filter((selection, index, self) =>
      index === self.findIndex((t) => t.value === selection.value)
    );
    let uniqueInitialAddons = initial_addons.filter((addon, index, self) =>
      index === self.findIndex((t) => t.product_id === addon.product_id)
    );
    let uniqueRecurringAddons = recurring_addons.filter((addon, index, self) =>
      index === self.findIndex((t) => t.product_id === addon.product_id)
    );

    setSelections(uniqueNewSelections);
    props.setData((prevData: any) => ({
      ...prevData,
      selectedInitialAddons: uniqueInitialAddons,
      selectedRecurringAddons: uniqueRecurringAddons,
    }));
  }, [value, rawInputValues]); // Add `rawInputValues` to dependencies

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else {
      setValue("");
      setSelections([]);
    }
  }, [props.planId]);
  
  useEffect(() => {  
    const addonOptions: OptionType[] = []; 
    
    const addons: any = plans[props.planId as keyof typeof plans] && plans[props.planId as keyof typeof plans]['addons'];
    
    for (const key in addons) {    
      
      const thisAddonId = addons[key as keyof typeof addons]['productId'].toString();
      const initialMin = addons[key as keyof typeof addons]['initialMin'];
      const recurringMin = props.monthly ? addons[key as keyof typeof addons]['monthlyMin'] 
                                          : addons[key as keyof typeof addons]['recurringMin'];
      const thisAddonName = toTitleCase(addons[key as keyof typeof addons]['name']);
      const thisAddonInitial = Math.max(addons[key as keyof typeof addons]['initialMin'], addons[key as keyof typeof addons]['initialMax']).toString();
      const thisAddonRecurring = props.monthly
          ? Math.max(addons[key as keyof typeof addons]['monthlyMin'], addons[key as keyof typeof addons]['monthlyMax']).toString()
          : Math.max(addons[key as keyof typeof addons]['recurringMin'], addons[key as keyof typeof addons]['recurringMax']).toString();
      const thisIsTaxable = addons[key as keyof typeof addons]['isRechar'];
      const isRecurring = addons[key as keyof typeof addons]['isRecurring'];
      let initialAddons: any;
      let recurringAddons: any;
      recurringAddons = {
        product_id: addons[key as keyof typeof addons]['productId'],
        amount: thisAddonRecurring,
        name: thisAddonName,
        quantity: 1,
        is_taxable: thisIsTaxable
      };
      initialAddons = {
        product_id: addons[key as keyof typeof addons]['productId'],
        amount: thisAddonInitial,
        name: thisAddonName,
        quantity: 1,
        is_taxable: thisIsTaxable
      };
      
      addonOptions.push({
        value: thisAddonId,
        label: thisAddonName,
        isRecurring: isRecurring,
        data: {
          initial: thisAddonInitial,
          recurring: thisAddonRecurring,
          initialMin: initialMin,
          initialMax: thisAddonInitial,
          recurringMin: recurringMin,
          recurringMax: thisAddonRecurring,
          selectedInitialAddons: initialAddons,
          selectedRecurringAddons: recurringAddons,
        }        
      });
    }
    setOptions(addonOptions);
  }, [plans, props.planId]);

  useEffect(() => {  
  let newInitialTotal = 0;
  let newRecurringTotal = 0;

  selections.forEach((thisSelection) => {
    if(thisSelection.data) {
      const productId = thisSelection.value;
      
      const initialValue = rawInputValues[`initial-${productId}`] !== undefined
        ? parseFloat(rawInputValues[`initial-${productId}`]) || 0
        : pr_initialAddons?.some((addon: any) => addon.product_id === Number(thisSelection.value))
          ? parseFloat(pr_initialAddons.find((addon: any) => addon.product_id === Number(thisSelection.value)).amount)
          : parseFloat(thisSelection.data.initial);
      newInitialTotal += initialValue;

      const recurringValue = rawInputValues[`recurring-${productId}`] !== undefined
        ? parseFloat(rawInputValues[`recurring-${productId}`]) || 0
        : pr_recurringAddons?.some((addon: any) => addon.product_id === Number(thisSelection.value))
          ? parseFloat(pr_recurringAddons.find((addon: any) => addon.product_id === Number(thisSelection.value)).amount)
          : parseFloat(thisSelection.data.recurring);
      newRecurringTotal += recurringValue;
    }
  });

  setInitialAddons(newInitialTotal);
  setRecurringAddons(newRecurringTotal);

  const baseInitialPrice = props.data?.pr_initial_charge ? parseFloat(props.data.pr_initial_charge) : 0;
  const baseRecurringPrice = props.data?.pr_service_charge ? parseFloat(props.data.pr_service_charge) : 0;
  props.setInitialTotal(newInitialTotal + baseInitialPrice);
  props.setRecurringTotal(newRecurringTotal + baseRecurringPrice);

}, [selections, rawInputValues, pr_initialAddons, pr_recurringAddons, props.data]);
  
  const [isChange, setIsChange] = useState<boolean>(true);
  const handleInputChange = (
    type: 'initial' | 'recurring',
    value: string,
    productId: number,
    selectionIndex: number
  ) => {
    if(isChange) {
      setRawInputValues(prev => ({
        ...prev,
        [`${type}-${productId}`]: value
      }));
      const newValue = parseFloat(value) || 0;
      const selection = selections[selectionIndex];
    
      if (!selection) return;
      const maxValue =
        type === 'initial'
          ? parseFloat(selection.data?.initialMax)
          : parseFloat(selection.data?.recurringMax);
      const minValue =
        type === 'initial'
          ? parseFloat(selection.data?.initialMin)
          : parseFloat(selection.data?.recurringMin);

      setSelections((prevSelections) =>
        prevSelections.map((selection, index) =>
          index === selectionIndex
            ? {
                ...selection,
                data: {
                  ...selection.data,
                  [type]: newValue.toString(),
                  [`selected${type === 'initial' ? 'Initial' : 'Recurring'}Addons`]: {
                    ...selection.data[
                      `selected${type === 'initial' ? 'Initial' : 'Recurring'}Addons`
                    ],
                    amount: newValue,
                  },
                },
              }
            : selection
        )
      );
    
      props.setData((prevData: any) => {
        const updatedAddons =
          type === 'initial'
            ? prevData.selectedInitialAddons.map((addon: any) =>
                addon.product_id ===
                selections[selectionIndex]?.data.selectedInitialAddons?.product_id
                  ? { ...addon, amount: newValue }
                  : addon
              )
            : prevData.selectedRecurringAddons.map((addon: any) =>
                addon.product_id ===
                selections[selectionIndex]?.data.selectedRecurringAddons?.product_id
                  ? { ...addon, amount: newValue }
                  : addon
              );
    
        return {
          ...prevData,
          [`selected${type === 'initial' ? 'Initial' : 'Recurring'}Addons`]:
            updatedAddons,
        };
      });
          
      if (newValue < minValue || newValue > maxValue) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [selectionIndex]: `Please enter a value above ${minValue} and below ${maxValue}`,
          type: type,
          style: {border: "1px solid red", color: "red"}
        }));

        setTooltipVisible(prev => ({
          ...prev,
          [`${type}-${selectionIndex}`]: true
        }));
        
        props.setAddonsErrors((prev: any) => ([{
          ...prev,
          error: `Please enter a value above ${minValue} and below ${maxValue}`
        }]));
        return;
      }
    
      setTooltipVisible(prev => ({
        ...prev,
        [`${type}-${selectionIndex}`]: false
      }));

      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors[selectionIndex];
        return newErrors;
      });
      props.setAddonsErrors([]);
    
      
    }
    setIsChange(false);
  };

  const handleKeyDown = (e: any) => {
    if (
      !/[0-9.]/.test(e.key) &&
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) // Allow control keys
    ) {
      e.preventDefault();
      setIsChange(false);
    } else {
      setIsChange(true);
    }

    if (e.key === '.' && e.currentTarget.value.includes('.')) {
      e.preventDefault();
      setIsChange(true);
    }
  };
  
  return (
    <AddonsStyles data-testid="addons-container">
      <FormField style={{marginBottom: '10px'}}>
        <Select
          placeholder={"Select"}
          options={options}
          onChange={(v) => setValue(v)}          
          value={value}
          isMulti
          withTags
          disabled={props.isIniAptCmplted}
        />
      </FormField>
      {selections.length
        ? selections.map((thisSelection, i) => (
          <div key={i}>
            <TextStyled color={'#4B5563'} className={'addons__item__label'}>{thisSelection.label}</TextStyled>
            <div className="addons__item__price">
              <TextStyled color={'#4B5563'} className={'addon__item__price__label'}>INITIAL RATE</TextStyled>
              <FormField className="price-input">
                <input 
                  value={
                    rawInputValues[`initial-${thisSelection.value}`] !== undefined
                    ? rawInputValues[`initial-${thisSelection.value}`]
                    : pr_initialAddons && 
                    pr_initialAddons.length > 0 && 
                    pr_initialAddons?.some((addons: any) => addons.product_id === Number(thisSelection.value)) ? 
                    thisSelection.data &&
                      parseFloat(pr_initialAddons[pr_initialAddons.findIndex(addon => addon.product_id === Number(thisSelection.value))]['amount']).toFixed(2)
                      : parseFloat(thisSelection.data?.initial).toFixed(2)}
                  onChange={(e) => handleInputChange('initial', e.target.value, thisSelection.value, i)}
                  onFocus={(e) => e.target.select()}
                  data-tooltip-id={errors[i] && errors.type === 'initial' ? `tooltip-initial-${i}` : undefined}
                  style={errors[i] && errors.type === "initial" ? errors.style: {}}
                  onKeyDown={handleKeyDown}
                  disabled={props.isIniAptCmplted || !canAddonPriceBeEdited(thisSelection.data.initialMin, thisSelection.data.initialMax)}
                />
                {errors[i] && errors.type === 'initial' &&
                  <Tooltip 
                    className={'error-tooltip'}
                    id={`tooltip-initial-${i}`}
                    place="top"
                    content={errors[i] || ''}
                    key={`initial-${i}`}
                    isOpen={tooltipVisible[`initial-${i}`]}
                    style={{ zIndex: 1000 }}
                  />
                }
              </FormField>
            </div>
            <div className="addons__item__price">
              <TextStyled color={'#4B5563'}>{props.monthly ? 'MONTHLY' : 'RECURRING'} RATE</TextStyled>
              <FormField className="price-input">
                <input 
                  value={
                    rawInputValues[`recurring-${thisSelection.value}`] !== undefined
                    ? rawInputValues[`recurring-${thisSelection.value}`]
                    : pr_recurringAddons.some((addons: any) => addons.product_id === Number(thisSelection.value)) ?
                    thisSelection.data && 
                      parseFloat(pr_recurringAddons[pr_recurringAddons.findIndex(addon => addon.product_id === Number(thisSelection.value))]['amount']).toFixed(2) 
                    : parseFloat(thisSelection.data?.recurring).toFixed(2)
                  }
                  onChange={(e) => handleInputChange('recurring', e.target.value, thisSelection.value, i)}
                  onFocus={(e) => e.target.select()}
                  data-tooltip-id={errors[i] && errors.type === 'recurring' ? `tooltip-recurring-${i}` : undefined}
                  style={errors[i] && errors.type === "recurring" ? errors.style: {}}
                  onKeyDown={handleKeyDown}
                  disabled={props.isIniAptCmplted || !canAddonPriceBeEdited(thisSelection.data.recurringMin, thisSelection.data.recurringMax)}
                />
                {errors[i] && errors.type === 'recurring' &&
                  <Tooltip
                    className={'error-tooltip'}
                    id={`tooltip-recurring-${i}`}
                    place="top"
                    content={errors[i]}
                    key={`recurring-${i}`} 
                    isOpen={tooltipVisible[`recurring-${i}`]}
                    style={{ zIndex: 1000 }}
                  />
                    
                }
              </FormField>
            </div>
            
          </div>
          ))
        : null}
        <div className="addons__total">
          <TextStyled 
            color={'#4B5563'} 
            className={'price-initial'}
          >
            Initial Addons
          </TextStyled>
          <TextStyled 
            color={'#4B5563'} 
            className={'price-initial'}
          >
            ${initialAddons.toFixed(2)} USD
          </TextStyled>                
        </div>
        <div className="addons__total">
          <TextStyled 
            color={'#4B5563'} 
            className={'price-recurring'}
          >            
            {props.monthly ? 'Monthly' : 'Recurring'} Addons
          </TextStyled>
          <TextStyled 
            color={'#4B5563'} 
            className={'price-recurring'}
          >
            ${recurringAddons.toFixed(2)} USD
          </TextStyled>                
        </div>
        
    </AddonsStyles>
  );
};

export default Addons;
