import styled from "@emotion/styled";
import { baseGray200, baseWhite, uiGray50 } from "styleConstants";

export const NotesStyles = styled.div`
  display: flex;
  width: 300px;
  border-right: 1px solid ${baseGray200};
  background: ${uiGray50};

  .note-children-container {
    display: flex;
    flex-direction: column;
  }

  .note-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .note-container {
    background: ${baseWhite};
    left: 0px;
    padding: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    border-bottom: 1px solid ${baseGray200};
  }

  .note-rep-name {
    color: #111827;
    font-size: 13px;
    font-family: Inter;
    font-weight: 600;
    line-height: 16px;
    word-wrap: break-word;
    padding-bottom: 4px;
  }

  .note-date {
    color: #a9a9a9;
    font-size: 10px;
    font-family: Inter;
    font-weight: 400;
    line-height: 12px;
    word-wrap: break-word;
    padding-bottom: 6px;
  }

  .note {
    flex: 1 1 0;
    color: #4b5563;
    font-size: 11px;
    font-family: Inter;
    font-weight: 400;
    line-height: 12px;
    word-wrap: break-word;
    text-align: left;
  }

  .add-note-container {
    padding: 8px;
    background: white;
    border-top: 1px ${baseGray200} solid;

    font-family: Inter;
    gap: 10px;
  }

  .add-note-input {
    font-family: Inter;
    border: 0;
    outline: none;
    width: 100%;
    height: 100%;
    resize: none;
    padding: 10px;
    box-sizing: border-box;

    &__wrapper {
      display: flex;
      flex-direction: column;
      padding: 0;
      overflow: hidden;
      align-items: end;
      max-height: 88px;
      height: 88px;
      box-sizing: border-box;
    }
  }

  .add-note-filled-button {
    background: #007AFF;
    border: 1px solid #007AFF;
  }
`;
