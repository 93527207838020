import React, { useEffect, useState } from "react";
import { useFeatureFlag } from "configcat-react";

import LeadInfoForm, { FormFieldType } from "../LeadMainInfo/LeadInfoForm";
import { Lead } from "atoms/lead";
import { stateOptions } from "../../../constants";

interface BillingProps {
  closer?: boolean;
  setData: React.Dispatch<React.SetStateAction<Lead>>;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  data: Lead;
  errors: string[];
  onSend: any;
  isMonthlyBilling?: boolean;
}

const BillingInfo = (props: BillingProps): React.JSX.Element => {
  const [sameBilling, setSameBilling] = useState<Boolean>(true);  
  const sameBillingDefaultValue = (props.data['pr_payment_profiles'] && props.data['pr_payment_profiles'].length) ?
                                  props.data['pr_payment_profiles'][0].ach_billing_address === props.data.address_line_1 : true;
  
  const login = JSON.parse(localStorage.getItem("okta-token-storage") || "{}").idToken?.claims.login;
  const user = { identifier: login, email: login };
  const { value: billingDay } = useFeatureFlag("billingDay", false, user as any);
  const { value: isAchAdditionalFieldsEnabled } = useFeatureFlag("isachadditionalfieldsenabled", false, user as any);

  const billingDateOptions = Array.from({ length: 28 }, (_, index) => ({
    value: index + 1,
    label: (index + 1).toString(),
  }));
                                    
  const billingFields: FormFieldType[] = [
    {
      label: "Credit Card Payment Contact",
      component: "payment",
      placeholder: "Payment",
      name: "credit_card_send_option",
      additionalClassName: "_large-field _no-padding",
      options: [
        { disabled: props.data.email_address ? false : true, value: "email", label: "Email" },
        { value: "sms", label: "SMS" },
      ],      
      defaultValue: props.data.email_address ? "email" : 'sms'
    },
    {
      label: "First name",
      component: "input",
      placeholder: "Enter your name",
      name: "ach_first_name",
      additionalClassName: "_p-right",
      required: true,
    },
    {
      label: "Last name",
      component: "input",
      placeholder: "Enter your name",
      name: "ach_last_name",
      additionalClassName: "_p-left",
      required: true,
    },
   
    {
      label: "Bank name",
      component: "input",
      placeholder: "e.g., Bank",
      name: "bank_name",
      id: "bank_name",
      additionalClassName: "_large-field _no-padding",
      required: true
    },
    {
      label: "Routing number",
      component: "input",
      placeholder: "xxxxxxxxx",
      name: "ach_routing_number",
      id: "ach_routing_number",
      additionalClassName: "_p-right",      
      required: true,
      validator: /^\d{9,9}$/
    },
    {
      label: "Account number",
      component: "input",
      placeholder: "xxxxx",
      name: "ach_account_number",
      id: "ach_account_number",
      additionalClassName: "_p-left",      
      required: true,
      validator: /^\d{5,17}$/
    },
    {
      label: "Auto pay",
      component: "radio",
      name: "ach_auto_pay",
      additionalClassName: "_large-field _no-padding",
      options: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      defaultValue: Number(props.data.ach_auto_pay)
    },
    {
      label: "Is billing address the same as service address?",
      component: "radio",
      name: "sameBilling",      
      onChange: (value: any) => {
        setSameBilling(value);
      },
      additionalClassName: "_large-field _no-padding",      
      options: [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ], 
      defaultValue: sameBillingDefaultValue
    },
  ];

  const billingAddressFields: FormFieldType[] = [      
    {
      label: "Address",
      component: "input",
      placeholder: "e.g., 456 Wasp Way",
      name: "ach_billing_address",
      id: "ach_billing_address",      
      additionalClassName: "_large-field _no-padding",
      autoComplete: "on",
    },
    {
      label: "City",
      component: "input",
      placeholder: "e.g., Boulder Bee",
      name: "ach_billing_city",
      id: "ach_billing_city",      
      additionalClassName: "_p-right",
    },
    {
      label: "State",
      component: "select",
      placeholder: "Washington",
      options: stateOptions,      
      name: "ach_billing_state",
      id: "ach_billing_state",
      additionalClassName: "_p-left",
    },
    {
      label: "Zip",
      component: "input",
      placeholder: "e.g., 90210",
      mask: "99999",
      name: "ach_billing_zip",
      id: "ach_billing_zip",
      regexp: /^[0-9\b]+$/,
      additionalClassName: "_p-right",
    },
  ];  

  const billingAccountTypeField = {
    label: "Account Type",
    component: "radio",
    name: "ach_account_type_id",
    additionalClassName: "_large-field _no-padding",
    options: [
      { value: "personal_checking", label: "Personal Checking" },
      { value: "personal_savings", label: "Personal Savings" },
      { value: "business_checking", label: "Business Checking" },
      { value: "business_savings", label: "Business Savings" },
    ],
    defaultValue: props.data.ach_account_type_id
  };

  const billingDayField = {
    label: "Billing Day",
    component: "nextBillingDate",
    placeholder: "Select billing day",
    name: "pr_next_billing_date",
    options: billingDateOptions,
    additionalClassName: "_p-right"
  };

  const [displayFields, setDisplayFields] = useState<FormFieldType[]>(billingFields);  

  const useSameAddress = () => {
    let newData = {...props.data};
    newData.ach_billing_address = newData.address_line_1;
    newData.ach_billing_city = newData.city;
    newData.ach_billing_state = newData.province;
    newData.ach_billing_zip = newData.postal_code;
    props.setData(newData); 
  };

  const clearAddress = () => {
    let newData = {...props.data};
    newData.ach_billing_address = "";
    newData.ach_billing_city = "";
    newData.ach_billing_state = "";
    newData.ach_billing_zip = "";
    props.setData(newData); 
  };

  useEffect(() => {
    let updatedBillingFields = [...billingFields];

    if (isAchAdditionalFieldsEnabled) {
      updatedBillingFields.splice(4, 0, billingAccountTypeField);
    }

    if (billingDay && props.isMonthlyBilling) {
      isAchAdditionalFieldsEnabled ? updatedBillingFields.splice(7, 0, billingDayField) : updatedBillingFields.splice(6, 0, billingDayField);
    }

    if(sameBilling !== undefined) {
      if (!sameBilling) {
        updatedBillingFields = [...updatedBillingFields, ...billingAddressFields];
      }
      sameBilling ? useSameAddress() : clearAddress();
    }

    setDisplayFields(updatedBillingFields);
  }, [isAchAdditionalFieldsEnabled, sameBilling, props.isMonthlyBilling, billingDay]);

  useEffect(() => {
    if (!props.data.ach_first_name && !props.data.ach_last_name) {
      props.setData(prevData => ({
        ...prevData,
        ach_first_name: props.data.name_first,
        ach_last_name: props.data.name_last
      }));
    }
  }, [props.data]);

  useEffect(() => {
    if (props.data?.pr_next_billing_date) return;

      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());

      props.setData((prevData: any) => ({
        ...prevData,
        pr_next_billing_date: `${nextMonth.getFullYear()}-${(nextMonth.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${nextMonth.getDate()
          .toString()
          .padStart(2, '0')}`
      }));
    }, [props.data]);

  return <LeadInfoForm {...props} formFields={displayFields} />;
};

export default BillingInfo;
