import * as React from "react";
import { LeadManagementMenuStyled } from "./LeadManagementMenu.Styles";
import MenuRadioItem from "./../MenuRadioComponent/MenuRadioItem";
import { FORM_STATES } from "./LeadManagement";
import { Lead } from "atoms/lead";

interface LeadManagementMenuProps {
  onChange: (s: FORM_STATES) => void;
  step: FORM_STATES;
  menuList: { label: string; step: FORM_STATES }[];
  completedSteps: FORM_STATES[];
  eventId?: string;
  setData: React.Dispatch<React.SetStateAction<Lead>>;
  setApiError: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveRequestText: React.Dispatch<React.SetStateAction<string>>;
  getLeadData: () => Promise<any>;
  cancelStep: (step: FORM_STATES) => void;
  completeStep: (step: FORM_STATES) => void;
}

const LeadManagementMenu = (
  props: LeadManagementMenuProps,
): React.JSX.Element => {
  return (
    <LeadManagementMenuStyled data-testid="lead-management-menu">
      <div className="left-menu__radio-wrapper">
        {props.menuList.map((item, i) => {
          const isComplete = props.completedSteps.includes(item.step);
          const isPreviousComplete = item.step - 1 >= 0 ? props.completedSteps.includes(item.step - 1) : true;
          return (
            <MenuRadioItem
              key={i}
              onClick={() => props.onChange(item.step)}
              label={item.label}
              isCompleted={props.completedSteps.includes(item.step)}
              isActive={props.step === item.step}
              isDisabled={!isComplete && !isPreviousComplete}
              eventId={props.eventId}
              setData={props.setData}
              setApiError={props.setApiError}
              setSaveRequestText={props.setSaveRequestText}
              getLeadData={props.getLeadData}
              cancelStep={props.cancelStep}
              completeStep={props.completeStep}
            />
          );
        })}
      </div>
    </LeadManagementMenuStyled>
  );
};

export default LeadManagementMenu;
