import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { NotesStyles } from "./Notes.Styles";
import createNote from "./../../api/createNote";
import { formatDate } from "./../../utils/utils";
import { loadingAtom } from "./../../atoms/loading";
import { leadAtom, Lead } from "./../../atoms/lead";

import IconButton from "./../Buttons/IconButton";
import { getContentTemplate } from "./../SectionHeader/SectionHeader";
import { FormField } from "./../Form/Input/Input.Styles";
import Select from "components/Form/Select/Select";
import { noteOptions } from "../../constants";
import { FlexContainer } from "components/Flex/Flex.Styles";
import { NotesHeaderStyles } from "./NotesHeader.Styles";

interface Note {
  created_at: string;
  created_by_username: string;
  note: string;
  system_id: number;
  note_type_id: number;
}
interface NoteProps {
  note: Note;
}


export const Note = (props: NoteProps): React.JSX.Element => (
  <div className="note-container" data-testid="note-container">
    <div className="note-rep-name">{props.note.created_by_username}</div>
    <div className="note-date">{formatDate(props.note.created_at)}</div>
    <div className="note-date">{noteOptions[Number(props.note.note_type_id) - 1]?.label}</div>
    <div className="note">{props.note.note}</div>
  </div>
);

interface NotesInputProps {
  submit: (e: React.FormEvent<HTMLFormElement>) => void;
  note: string;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  submitOnEnter: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleSelectChange: (selected: string) => void;
  value: string;
}

export const NotesInput = (props: NotesInputProps): React.JSX.Element => {
  const [focused, setFocused] = useState(false);
  
  return (
    <div className="add-note-container" data-testid="add-note-container">
      <FormField>
        <form
          onSubmit={props.submit}
          className={`add-note-input__wrapper ${focused ? "_focused" : ""}`}
        >
          <textarea
            className="add-note-input"
            name="newComment"
            id="newComment"
            value={props.note}
            rows={5}
            onChange={(e) => props.setNote(e.target.value)}
            onKeyDown={props.submitOnEnter}
            placeholder="Write a note..."
            onBlur={() => setFocused(false)}
            onFocus={() => setFocused(true)}
          />
          <FlexContainer alignItems="center" style={{padding: "6px"}}>
            <Select
              options={noteOptions}
              value={props.value}
              onChange={props.handleSelectChange}
              placeholder={"Select an option"}
              isGroupable
            />
            <IconButton
              type="submit"
              className={props.note ? "add-note-filled-button" : ""}
              icon={props.note ? "PaperAirplane-filled" : "PaperAirplane"}
              tooltip="Submit"
            />
          </FlexContainer>
          
        </form>
      </FormField>
    </div>
  );
};

const Notes = (): React.JSX.Element => {
  let { leadId } = useParams();
  const notesContainerRef = useRef<HTMLDivElement>(null);
  const leadData = useRecoilValue(leadAtom);
  const setLoading = useSetRecoilState(loadingAtom);
  const [notes, setNotes] = useState<Note[]>([]);
  const [newNote, setNewNote] = useState("");
  const [selectedNoteType, setSelectedNoteType] = useState("1");
  const [value, setValue] = useState("1");

  const handleSelectChange = ( selectedValue: string) => {
    setSelectedNoteType(selectedValue);
    setValue(selectedValue);
  };

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    saveNote();
  };

  const submitOnEnter = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey == false) {
      saveNote();
    }
  };

  const saveNote = async () => {
    try {
      setLoading(true);
      const response = await createNote(leadId, newNote, Number(selectedNoteType));
      const noteToAdd: Note = response.data;
      setNotes((prevNotes) => [...prevNotes, noteToAdd]);
      setNewNote("");
      setTimeout(() => {
        if (notesContainerRef && notesContainerRef.current) {
          notesContainerRef.current.scrollTo({
            top: notesContainerRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }
      }, 100);
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
  };

  useEffect(() => {
    let noteHistory = leadData ? (leadData as Lead).notes || [] : [];
    const sortedNotes = [...noteHistory].sort((a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
    setNotes(sortedNotes);
  }, [leadData]);

  return (
    <NotesStyles className="notes-parent-container" data-testid="notes-parent-container">
      <NotesHeaderStyles>
        <div className="container" data-testid="section-header-container">
          <div className="header-icon-container">
            <div
              className="header-icon"
              style={{ content: getContentTemplate("Pencil") }}
            />
          </div>
          <div className="header-label">Notes</div>
        </div>
      </NotesHeaderStyles>
      {/* <SectionHeader label="Notes" icon="Pencil" /> */}
      <div ref={notesContainerRef} className="note-list-container">
        {
          notes && notes.map((thisNote: Note, key: number) => (
            <Note note={thisNote} key={key} />
          ))
        }
      </div>
      <NotesInput
        note={newNote}
        setNote={setNewNote}
        submit={submit}
        submitOnEnter={submitOnEnter}
        handleSelectChange={handleSelectChange}
        value={value}
      />
    </NotesStyles>
  );
};

export default Notes;
