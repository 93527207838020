import React, { useState, useEffect } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useFeatureFlag } from "configcat-react";
import { useLocation, useNavigate } from "react-router-dom";

import createLead from "./../../api/createLead";
import { updateLead } from "./../../api/updateLead";
import { createCustomer } from "./../../api/createCustomer";
import { billing } from "./../../api/billing";

import { leadAtom, Lead } from "./../../atoms/lead";
import { plansAtom } from "./../../atoms/plans";
import { employeeAtom, Employee } from "./../../atoms/employee";
import { errorBannerAtom } from "./../../atoms/errorBanner";

import { loadingAtom } from "./../../atoms/loading";
import getLead from "./../../api/getLead";
import getPlans from "./../../api/getPlans";

import PlanInfo from "./../../components/LeadManagement/PlanInfo/PlanInfo";
import BillingInfo from "./BillingInfo/BillingInfo";
import SchedulingInfo from "./SchedulingInfo/SchedulingInfo";
import Spinner from "./../../components/Spinner/Spinner";
import { FlexContainer } from "./../../components/Flex/Flex.Styles";
import LeadManagementMenu from "./../../components/LeadManagement/LeadManagementMenu";
import Dispositions from "./../../components/Dispositions/Dispositions";
import Notes from "./../../components/Notes/Notes";
import LeadMainInfo from "./../../components/LeadManagement/LeadMainInfo/LeadMainInfo";
import {
  LeadManagementStyled,
  LeadActionButtonsStyles,
} from "./LeadManagement.Styles";

import LeadBar from "./LeadBar";
import ErrorBanner from "./ErrorBanner";

import Notification from "./../Notification/Notification";
import {
  ButtonSecondary,
  ButtonPrimary,
} from "components/Buttons/Button.Styled";
import SectionHeader from "./../SectionHeader/SectionHeader";
import { scheduling } from "api/schedule";
import { getAppointment } from "api/getAppointment";
import { rescheduling } from "api/rescheduling";
import { cancelAppointment } from "api/cancelApppointment";
import { ENV } from "../../constants";
import { Slot } from "./LeadMainInfo/LeadInfoForm";

export enum FORM_STATES {
  personalInfo,
  planInformation,
  schedule,
  billingInformation,
  notes,
  disposition,
}

interface LeadManagementProps {
  closer?: boolean;
  leadId?: string;
  outbound?: boolean;
  showMenu?: boolean;
  showBilling?: boolean;
  showSchedule?: boolean;
}

const setterMenu = [
  {
    label: "Personal info",
    step: FORM_STATES.personalInfo,
  },
];

export interface LeadPlanInfo {
  pr_event_id?: number;
  pr_customer_id?: number;
  pr_subscription_id?: number;
  pr_service_id?: number;
  pr_sold_by_id?: string;
  pr_source_id?: number;
  pr_service_charge?: number;
  pr_initial_charge?: number;
  pr_created_at?: string;
  pr_agreement_length?: number;
  pr_is_initial_appointment_completed?: boolean;
  selectedInitialAddons?: any
  selectedRecurringAddons?: any
}

export interface LeadBillingInfo {
  ach_first_name?: string,
  ach_last_name?: string,
  ach_billing_address?: string,
  ach_billing_city?: string,
  ach_billing_state?: string,
  ach_billing_zip?: string,
  ach_billing_country?: string,
  ach_routing_number?: string,
  ach_account_number?: string,
  ach_auto_pay?: number,
  bank_name?: string,
}

export interface LeadSchedulingInfo {
  date?: any;
  time?: string;
  spotID?: any;
  window?: any;
  isAroSpot?: any;
  additions?: any;
  selected_apt?: any;
  pr_appointment_id?: any;
}

export interface AppointmentResponseType {
  data: {
      success: boolean;
      customerId: string;
      appointment: {
          appoinmentId: string;
          date: string;
          start: string;
          appointmentType: string;
          appointmentTypeName: string;
      } | null;
  }
}

export const fieldsInitialData: Lead & LeadPlanInfo & LeadBillingInfo & LeadSchedulingInfo = {
  ach_first_name: "",
  ach_last_name: "",
  ach_billing_address: "",
  ach_billing_city: "",
  ach_billing_state: "",
  ach_billing_zip: "",
  ach_billing_country: "US",
  ach_routing_number: "",
  ach_account_number: "",
  ach_account_last_four: "",
  ach_account_type_id: "personal_checking",
  ach_auto_pay: 1,
  bank_name: "",
  name_first: "",
  name_last: "",
  contact_id: 0,
  email_address: "",
  system_id: 0,
  phone_number: "",
  phone_number_2: "",
  address_line_1: "",
  city: "",
  province: "",
  postal_code: "",
  pest_types: "",
  property_type: "",
  property_type_id: null,
  pr_event_id: 0,
  pr_customer_id: 0,
  pr_subscription_id: 0,
  pr_service_id: 0,
  pr_sold_by_id: "",
  pr_source_id: 0,
  pr_service_charge: 0,
  pr_initial_charge: 0,
  pr_created_at: "",
  pr_payment_profiles: [],
  service_type: "",
  pr_agreement_length: 0,
  date: "",
  time: "",
  qr_code_id: 0,
  pr_next_billing_date: null,
};

const PHONE_NUMBER_LENGTH = 10;

const formatPhone = (phone?: string) => phone?.replace(/\D/g, "") || "";

const LeadManagement = (props: LeadManagementProps): React.JSX.Element => {
  const navigate = useNavigate();
  const [leadData, setLead] = useRecoilState(leadAtom);
  const [plans, setPlans] = useRecoilState(plansAtom);
  const [isIniAptCmplted, setIsIniAptCmplted] = useState<boolean>(false);

  const employee = useRecoilValue(employeeAtom);
  const setErrorBanner = useSetRecoilState(errorBannerAtom);
  
  const resetLead = useResetRecoilState(leadAtom);

  const [loading, setLoading] = useRecoilState(loadingAtom);
  const [step, setStep] = useState(FORM_STATES.personalInfo);
  const [isCustomer, setIsCustomer] = useState(false);
  const [completedSteps, setCompletedSteps] = useState<FORM_STATES[]>([]);
  const [data, setData] = useState<Lead & LeadPlanInfo & LeadBillingInfo & LeadSchedulingInfo>(
    fieldsInitialData,
  );
  const [initialPlanInfoData, setInitialPlanInfoData] = useState<LeadPlanInfo>({
    pr_service_id: 0,
    pr_service_charge: 0,
    pr_initial_charge: 0,
  });

  const login = JSON.parse(localStorage.getItem("okta-token-storage") || "{}").idToken?.claims.login;
  const user = { identifier: login, email: login };

  const { value: leadManagementMenuValue, loading: leadManagementMenuLoading } =
  useFeatureFlag("leadManagementMenu", false);
  const { value: dispositionsValue, loading: dispositionsLoading } =
  useFeatureFlag("dispositions", false);
  const { value: billingValue } =
  useFeatureFlag("leadBilling", false);
  const { value: schedulingValue } =
  useFeatureFlag("leadSchedule", false, user as any);

  const [isMonthlyBilling, setIsMonthlyBilling] = useState(false);

  const [errors, setErrors] = useState<string[]>([]);
  const [errorsAddons, setErrorsAddons] = useState<string[]>([]);
  const [saveRequestText, setSaveRequestText] = useState("");
  const [apiError, setApiError] = useState<boolean>(false);
  const [buttonLabel, setButtonLabel] = useState("Save");
  const [buttonAction, setButtonAction] = useState(() => () => save(data));
  const [isSlotSelected, setIsSlotSelected] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState<Slot>({ start: '', end: ''});
  const allowContactEditing = ENV !== 'production' && new URLSearchParams(useLocation().search).get('allowContactEditing');

  const handleSlotSelect = (isSelected: boolean, date: any, slot: Slot, addition: any) => {
    setIsSlotSelected(isSelected);
    setSelectedDate(date);
    setSelectedSlot(slot);
    setData(prevData => ({
      ...prevData,
      date: date?.value,
      selected_apt: addition,
    }));
  };

  const closerMenu = [
    ...setterMenu,
    {
      label: "Plan info",
      step: FORM_STATES.planInformation,
    },
  ];

  if(schedulingValue || props.showSchedule) {
    closerMenu.push({
      label: "Schedule",
      step: FORM_STATES.schedule,
    });
  }
  /* added prop for testing */
  if(billingValue || props.showBilling) {
    closerMenu.push({
      label: "Billing info",
      step: FORM_STATES.billingInformation,
    });
  }


  const menu = props.closer ? closerMenu : setterMenu;

  async function getLeadData () {
    setLoading(true);
    try {
      const leadResponse = await getLead(props.leadId);
      
      setInitialPlanInfoData({
        pr_service_id: leadResponse.data.pr_service_id,
        pr_service_charge: leadResponse.data.pr_service_charge,
        pr_initial_charge: leadResponse.data.pr_initial_charge,
      });
  
      if (leadResponse.data.pr_initial_addons || leadResponse.data.pr_recurring_addons) {
        const tempData = {
          ...data,
          selectedInitialAddons: leadResponse.data.pr_initial_addons || [],
          selectedRecurringAddons: leadResponse.data.pr_recurring_addons || [],
        };
        setData(prevData => ({
          ...prevData,
          ...tempData,
        }));
      }
  
      setLead(prevLead => ({
        ...(prevLead || {}),
        ...fieldsInitialData,
        ...leadResponse.data,
        selectedInitialAddons: leadResponse.data.pr_initial_addons || [],
        selectedRecurringAddons: leadResponse.data.pr_recurring_addons || [],
      }));
      leadResponse.data?.pr_is_initial_appointment_completed && setIsIniAptCmplted(allowContactEditing ? false : leadResponse.data?.pr_is_initial_appointment_completed);
      setLoading(false);
      return leadResponse.data;
    } catch (e) {
      setApiError(true);
      setSaveRequestText("Failure to get lead data.");
    }
  
    setLoading(false);
    return null;
  }

  async function getPlanData (postalCode: string) {
    if (!postalCode) return;

    setLoading(true);
    try {
      const planResponse = await getPlans(postalCode);
      setPlans(planResponse.data);
    } catch (e) {
      setApiError(true);
      setSaveRequestText("Failure to get plans.");
    }

    setLoading(false);
  }

  const createNewLead = (lead: Lead) =>
    createLead({
      ...lead,
      phone_number: formatPhone(lead.phone_number),
      phone_number_2: formatPhone(lead.phone_number_2),
    });


  const getChangedFields = (lead: Lead) => {
    const modifiedLead: Record<string, string> = {};

    for (const [key, value] of Object.entries(lead)) {
      if (leadData && value !== leadData[key]) {
        modifiedLead[key] = value;
      }
    }

    modifiedLead['sold_by_id'] = (employee as unknown as Employee)?.masterEmployeeId || "0";

    return modifiedLead;
  };

  const updateCurrentLead = (lead: Lead) => {
    const planInfo = {
      "service_id": parseInt(lead.pr_service_id as unknown as string),
      "sold_by_id": (employee as unknown as Employee)?.masterEmployeeId || "0",
      "service_charge": parseFloat(lead.pr_service_charge as unknown as string),
      "initial_charge": parseFloat(lead.pr_initial_charge as unknown as string),
      "agreement_length": parseInt(lead.pr_agreement_length as unknown as string),
      "is_monthly_billing": isMonthlyBilling,
      "initial_addons": JSON.stringify(data?.selectedInitialAddons),
      "recurring_addons": JSON.stringify(data?.selectedRecurringAddons),
      ...(lead.pr_next_billing_date && { "next_billing_date": lead.pr_next_billing_date }),
    };

    return updateLead(props.leadId, lead, planInfo);
  };

  const save = async (lead: Lead) => {
    try {
      let newLeadData = null;

      setLoading(true);
      if (data.system_id) {
        const updatedFields = getChangedFields({
          ...lead,
          phone_number: formatPhone(lead.phone_number),
          phone_number_2: formatPhone(lead.phone_number_2),
          qr_code_id: data.qr_code_id || 0
        });

        if (!Object.keys(updatedFields).length) {
          setSaveRequestText("No changes to save");
          nextStep();
          setLoading(false);
          return;
        }

        // Updates lead object with new lead data from response
        const response = await updateCurrentLead(updatedFields);
        if (response.status === 200) {
          if (response.data.pr_initial_addons || response.data.pr_recurring_addons) {
            const tempData = {
              ...data,
              selectedInitialAddons: response.data.pr_initial_addons || [],
              selectedRecurringAddons: response.data.pr_recurring_addons || [],
            };
            setData(prevData => ({
              ...prevData,
              ...tempData,
            }));
          }
      
          setLead(prevLead => ({
            ...(prevLead || {}),
            ...fieldsInitialData,
            ...response.data,
            selectedInitialAddons: response.data.pr_initial_addons || [],
            selectedRecurringAddons: response.data.pr_recurring_addons || [],
          }));
          setApiError(false);
          setSaveRequestText("The record has been successfully saved");
        }

        completeStep(FORM_STATES.personalInfo);
        nextStep();
      } else {
        newLeadData = await createNewLead(lead);
        navigate(`/inbound-setter/${newLeadData.data.system_id}`);
        completeStep(FORM_STATES.personalInfo);
        nextStep();
      }

      setLoading(false);
    } catch (e: any) {
      setApiError(true);
      if (e.response?.data?.errors) {
        const errorFields = Object.keys(e.response.data.errors);
        setErrors(errorFields);
        const errorMessage = Object.values(e.response.data.errors).join(", ");
        setSaveRequestText(errorMessage);
      } else {
        setSaveRequestText(e.message);
      }
      setLoading(false);
    }
  };

  const createNewCustomer = async (lead: Lead) => {
    try {
      setLoading(true);
      if (data.system_id) {
        const planInfo =
          {
            "service_id": parseInt(lead.pr_service_id as unknown as string),
            "sold_by_id": (employee as unknown as Employee)?.masterEmployeeId || "0",
            "service_charge": parseFloat(lead.pr_service_charge as unknown as string),
            "initial_charge": parseFloat(lead.pr_initial_charge as unknown as string),
            "agreement_length": parseInt(lead.pr_agreement_length as unknown as string),
            "is_monthly_billing": isMonthlyBilling,
            "initial_addons": JSON.stringify(data?.selectedInitialAddons),
            "recurring_addons": JSON.stringify(data?.selectedRecurringAddons),
          };
          
        const createResponse = await createCustomer(props.leadId, planInfo);
        if (createResponse.status === 200) {
          setApiError(false);
          setSaveRequestText("The customer has been successfully created");
          setData(prevData => ({
            ...prevData,
            selectedInitialAddons: createResponse.data.pr_initial_addons || [],
            selectedRecurringAddons: createResponse.data.pr_recurring_addons || [],
          }));
          
          setLead(prevLead => ({
            ...(prevLead || {}),
            ...createResponse.data,
            selectedInitialAddons: createResponse.data.pr_initial_addons || [],
            selectedRecurringAddons: createResponse.data.pr_recurring_addons || [],
          }));
          completeStep(FORM_STATES.planInformation);
        }
      }

      setLoading(false);
      nextStep();
    } catch (e: any) {
      const message = e.response.data.errors && e.response.data.errors[0]?.detail || e.message;
      setApiError(true);
      setSaveRequestText(message);
      setLoading(false);
    }
  };

  const billingRequest = async (lead: Lead) => {
    try {
      setLoading(true);
      if (data.system_id) {
        const accountLastFour = lead.ach_account_number
          ? lead.ach_account_number.slice(-4)
          : undefined;
        const billingInfo = {
            'customer_id': lead.pr_customer_id,
            'ach_cc': 'ach',
            'ach_billing_country': 'US',
            ...(lead.ach_first_name && {'ach_first_name': lead.ach_first_name}),
            ...(lead.ach_last_name && {'ach_last_name': lead.ach_last_name}),
            ...(lead.ach_billing_address && {'ach_billing_address': lead.ach_billing_address}),
            ...(lead.ach_billing_city && {'ach_billing_city': lead.ach_billing_city}),
            ...(lead.ach_billing_state && {'ach_billing_state': lead.ach_billing_state}),
            ...(lead.ach_billing_zip && {'ach_billing_zip': lead.ach_billing_zip}),
            ...(lead.bank_name && {'bank_name': lead.bank_name}),
            ...(lead.ach_routing_number && {'ach_routing_number': lead.ach_routing_number}),
            ...(lead.ach_account_number && {'ach_account_number': lead.ach_account_number}),
            ...(accountLastFour && { 'ach_account_last_four': accountLastFour }),
            ...(lead.ach_account_type_id && {'ach_account_type_id': lead.ach_account_type_id}),
            ...(lead.ach_auto_pay && {'ach_auto_pay': lead.ach_auto_pay}),
        };

        const aptResponse = await getAppointment(props.leadId);

        let nextBillingDate;

        if (aptResponse.status === 200 ) {
          nextBillingDate =
            aptData?.data?.appointment?.date &&
            lead.pr_next_billing_date &&
            new Date(aptData.data.appointment.date) > new Date(lead.pr_next_billing_date) ?
            aptData?.data.appointment.date : lead.pr_next_billing_date;
        }

        const updatedFields = getChangedFields({
          ...lead,
          pr_next_billing_date: nextBillingDate,
          phone_number: formatPhone(lead.phone_number),
          phone_number_2: formatPhone(lead.phone_number_2),
          qr_code_id: data.qr_code_id || 0
        });

        const UpdateNextBillingResponse = await updateCurrentLead(updatedFields);
        const createResponse = await billing(props.leadId, billingInfo);
        
        if (createResponse.status === 200 && UpdateNextBillingResponse.status === 200) {
          setApiError(false);
          setSaveRequestText("The record has been successfully saved");          
          getLeadData();
          completeStep(FORM_STATES.billingInformation);
        }
      }

      setLoading(false);
      nextStep();
    } catch (e: any) {
      const errors = e.response.data.errors;      
      const message = (Array.isArray(errors) && errors[0]?.detail) || 
                      (errors && Object.values(errors)[0]) || 
                      e?.message || "Failed to save billing info";
      setApiError(true);
      setSaveRequestText(message);
      setLoading(false);
    }
  };

  const sendAch = async (option: String) => {
    try {
      setLoading(true);
      if (data.system_id) {
        const createResponse = await billing(props.leadId, { 'ach_cc': 'cc' , "credit_card_send_option": option });
        if (createResponse.status === 200) {
          setApiError(false);
          if(option === 'email') {
            setSaveRequestText("Email Sent");
          } else {
            setSaveRequestText("SMS Sent");
          }
        }
      }

      setLoading(false);
    } catch (e: any) {
      const message = e.response.data.errors[0]?.detail || e.message;
      setApiError(true);
      setSaveRequestText(message);
      setLoading(false);
    }
  };

  const schedulingRequest = async () => {
    try {      
      setLoading(true);
      
      if (data.system_id) {
        const schedule_info = {
          spot_id: data?.selected_apt?.spot_id,
          appointment_type: 1,
          is_aro_spot: data?.selected_apt?.is_aro_spot,
          start: selectedSlot?.start,
          end: selectedSlot?.end,
        };
        
        try {
          if (data?.pr_appointment_id) {
            if(!data.email_address) {
              setErrorBanner({
                open: true,
                message: 'Email has not been set. Please add an email on the Personal Info tab.'
              });
            }
            const response = await rescheduling(props.leadId, schedule_info);
            if (response.status === 200) {
              setApiError(false);
              setSaveRequestText("The appointment has been successfully rescheduled");
              getAptData();
              await getLeadData();
              completeStep(FORM_STATES.schedule);
            }
          } else {
            if(!data.email_address) {
              setErrorBanner({
                open: true,
                message: 'Email has not been set. Please add an email on the Personal Info tab.'
              });
            }
            const response = await scheduling(props.leadId, schedule_info);
            if (response.status === 200) {
              setApiError(false);
              setSaveRequestText("The appointment has been successfully scheduled");
              getAptData();
              await getLeadData();
              completeStep(FORM_STATES.schedule);
            }
          }
          nextStep();
        } 
        catch (err: any) {
          setApiError(true);
          setSaveRequestText("We have difficulty to make a scheduling for this spot.");
          setLoading(false);
        }
      }

      setLoading(false);
      nextStep();
    } catch (e: any) {
      console.log(e);
      setApiError(true);
      setSaveRequestText("Scheduling Error");  
      setLoading(false);    
    }
  };

  const nextStep = () =>{
    if(props.closer) {
      switch(step) {
        case FORM_STATES.personalInfo:
          goToStep(FORM_STATES.planInformation);
          break;
        case FORM_STATES.planInformation:
          if(schedulingValue) {
            goToStep(FORM_STATES.schedule);
          } else if(billingValue) {
            goToStep(FORM_STATES.billingInformation);
          }
          break;
        case FORM_STATES.schedule:
          if(billingValue) {
            goToStep(FORM_STATES.billingInformation);
          }
          break;
      }
    }
  };

  const cancel = () => {
    setData(leadData || fieldsInitialData);
    setErrors([]);
  };

  const areRequiredFieldsFilled = (
    data: Lead & LeadBillingInfo & LeadSchedulingInfo,
    step: FORM_STATES,
  ) => {
    let isFilled;

    if (step === FORM_STATES.personalInfo) {
      isFilled = formatPhone(data.phone_number).length === PHONE_NUMBER_LENGTH;
      if (props.closer) {
        isFilled = isFilled &&
          data.name_first?.length !== 0 &&
          data.name_last?.length !== 0 &&
          data.address_line_1?.length !== 0 &&
          data.city?.length !== 0 &&
          data.postal_code?.length !== 0 &&
          data.province?.length !== 0;
      }
    }

    if (step === FORM_STATES.planInformation) {
      const validServiceType = (data?.pr_service_id !== 0);
      const validContractLength = (data?.pr_agreement_length !== 0);
      const validInitialPricing = (data?.pr_initial_charge !== 0);
      const validRecurringPricing = (data?.pr_service_charge !== 0);

      isFilled = validServiceType && validContractLength && validInitialPricing && validRecurringPricing;
    }

    if (step === FORM_STATES.billingInformation) {
      isFilled =
          data.ach_first_name !== undefined && data.ach_first_name?.length !== 0 &&
          data.ach_last_name !== undefined && data.ach_last_name?.length !== 0 &&
          data.ach_routing_number !== undefined && data.ach_routing_number?.length !== 0 &&
          data.ach_account_number !== undefined && data.ach_account_number?.length !== 0 &&          
          data.ach_billing_address !== undefined && data.ach_billing_address?.length !== 0 &&
          data.ach_billing_city !== undefined && data.ach_billing_city?.length !== 0 &&
          data.ach_billing_zip !== undefined && data.ach_billing_zip?.length !== 0 &&
          data.ach_billing_state !== undefined && data.ach_billing_state?.length !== 0 && 
          data.ach_account_type_id !== undefined && data.ach_account_type_id?.length !== 0 &&
          data.ach_auto_pay !== undefined;
    }

    if (step === FORM_STATES.schedule) {
      const filled =
        formatPhone(data.phone_number).length === PHONE_NUMBER_LENGTH;
      if (data.date) {
        isFilled = filled && data.date;
      } else {
        isFilled = filled;
      }
    }

    return isFilled;
  };

  const completeStep = (step: FORM_STATES) =>
    setCompletedSteps((prev) => (prev.includes(step) ? prev : [...prev, step]));

  const cancelStep = (step: FORM_STATES) =>
    setCompletedSteps((prev) => prev.filter((s) => s !== step));

  const goToStep = (s: FORM_STATES) => setStep(s);

  const getStep = () => {
    switch (step) {
      case FORM_STATES.personalInfo:
        return {
          component: (
            <LeadMainInfo
              closer={props.closer}
              data={data}
              errors={errors}
              setErrors={setErrors}
              setData={setData}
              isIniAptCmplted={isIniAptCmplted}
              isServiceability={isServiceability}
            />
          ),
          label: "Basic information",
          icon: "Clipboard",
        };
      case FORM_STATES.planInformation:
        return {
          component: (
            <PlanInfo
              closer={props.closer}
              data={data}
              initialPlanInfoData={initialPlanInfoData}
              errors={errors}
              setErrors={setErrors}
              setAddonsErrors={setErrorsAddons}
              setData={setData}
              isMonthlyBilling={isMonthlyBilling}
              isIniAptCmplted={isIniAptCmplted}
            />
          ),
          label: "Plan information",          
          icon: "Menu",
        };
      case FORM_STATES.billingInformation:
        return {
          component: (
            <BillingInfo
              closer={props.closer}
              data={data}
              errors={errors}
              setErrors={setErrors}
              setData={setData}
              isMonthlyBilling={isMonthlyBilling}
              onSend={(option: String) => sendAch(option)}
            />
          ),
          label: "Billing information",
          icon: "CreditCard",
        };
      case FORM_STATES.schedule:
        return {
          component: (
            <SchedulingInfo
              closer={props.closer}
              data={data}
              errors={errors}
              setErrors={setErrors}
              setApiError={setApiError}
              setSaveRequestText={setSaveRequestText}
              setData={setData}
              eventId={props.leadId}
              step={step}
              isCancelApt={isCancelApt}
              cancelApt={cancelApt}
              onSlotSelect={handleSlotSelect}
              selectedDate={selectedDate}
              selectedSlot={selectedSlot}
            />
          ),
          label: "Scheduling information",
          icon: "Calendar",
        };
    }
  };

  const [isCancelApt, setIsCancelApt] = useState<any>();
  
  const cancelApt = async () => {
    try{
      setLoading(true);
      const aptRes = await cancelAppointment(props.leadId);
      if(aptRes.data) {
        setIsCancelApt(true);
        setApiError(false);
        setSaveRequestText(aptData.data?.message);
        setIsSlotSelected(false);
        setData({...data, appointment: null});
        getLeadData();
        cancelStep(FORM_STATES.schedule);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const [aptData, setAptData] = useState<any>();
    
  const getAptData = async () => {
    try {
      const aptRes = await getAppointment(props.leadId);
      if (aptRes.data) {
        setAptData(aptRes.data);
        aptRes.data?.data?.success === true && !isIniAptCmplted && setIsCancelApt(false);
      }
    } catch (error) {
      setAptData({data: false});
    }
  };

  useEffect(() => {
    step == FORM_STATES.schedule && getAptData();
  }, [step]);

  useEffect(() => {
    if(step == FORM_STATES.schedule) {
      setData({...data, appointment: aptData?.data});
    }
  }, [aptData]);
  
  
  useEffect(() => {
    if (props.closer) {
      if (step === FORM_STATES.planInformation && !isCustomer) {
        setButtonLabel("Create Customer");
        setButtonAction(() => () => createNewCustomer(data));
      } else if (step === FORM_STATES.billingInformation || step === FORM_STATES.schedule) {
        setButtonLabel("Save");
        if(step === FORM_STATES.billingInformation) {
          setButtonAction(() => () => billingRequest(data));
        }
        if(step === FORM_STATES.schedule) {
          setButtonAction(() => () => schedulingRequest());
        }
      } else {
        setButtonLabel("Next");
        setButtonAction(() => () => save(data));
      }
    } else {
      setButtonAction(() => () => save(data));
    }
  }, [step, data]);

  useEffect(() => {
    if(data['pr_payment_profiles'] && data['pr_payment_profiles'].length > 0) {
      const mostRecentPaymentProfile = data['pr_payment_profiles']?.length 
        ? data['pr_payment_profiles']?.reduce((latest, profile) => {
          return new Date(profile.created_at) > new Date(latest.created_at) ? profile : latest;
      }, data.pr_payment_profiles[0]) : null;
      
      setLead({
       ...data,
       ...mostRecentPaymentProfile
      });
    }

  }, [data['pr_payment_profiles']]);

  useEffect(() => {
    if (leadData) {
      let newLeadData = {
        ...leadData as any,
        appointment: data?.appointment
      };
      setData(newLeadData);

      if(props.closer) {
        getPlanData(leadData['postal_code']);

        if(areRequiredFieldsFilled(leadData, FORM_STATES.personalInfo)) {
          completeStep(FORM_STATES.personalInfo);
        }

        if (leadData['pr_customer_id'] !== 0) {
          setIsCustomer(true);
          completeStep(FORM_STATES.personalInfo);
          completeStep(FORM_STATES.planInformation);
        }

        // At least 1 payment profile was made
        if (leadData['pr_payment_profiles'] && leadData['pr_payment_profiles'][0]) {
          setIsCustomer(true);
          completeStep(FORM_STATES.billingInformation);
        }

        if (leadData['pr_appointment'] && leadData['pr_appointment']['appointmentId']) {
          setIsCustomer(true);
          completeStep(FORM_STATES.schedule);
        }
      }
    } else {
      let temp: any = {
        ...fieldsInitialData,
      };
      temp.appointment = data.appointment;
      setData(temp);
    }
  }, [leadData]);

  useEffect(() => {
    if (props.leadId) {
      getLeadData();      
    } else {
      resetLead();
    }
  }, [props.leadId]);

  useEffect(() => {    
    let leadMonthly = false;
    let planMonthly = false;
    if (leadData) {
      leadMonthly = leadData['pr_is_monthly_billing'];
    }

    const firstPlanKey = Object.keys(plans)[0];
    if (firstPlanKey) {      
      planMonthly = plans[firstPlanKey as keyof typeof plans]['isMonthlyBilling'];
    }

    setIsMonthlyBilling(leadMonthly || planMonthly);
  }, [leadData, plans]);

  const [isServiceability, setIsServiceability] = useState(false);
  
  return (
    <>
      <LeadManagementStyled id="main-container" data-testid="lead-management">
        {loading && <Spinner overlay={true} />}
        <ErrorBanner />
        <LeadBar setApiError={setApiError} setSaveRequestText={setSaveRequestText} setIsServiceability={setIsServiceability}/>
        <FlexContainer
          className={`lead-page ${props.closer ? "_closer" : "_setter"}`}
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <FlexContainer column alignItems="stretch">
            <SectionHeader
              label={getStep()?.label || ""}
              icon={getStep()?.icon || ""}
              monthlyBanner={isMonthlyBilling}
            />
            <FlexContainer
              className="lead-page__section__wrapper"
              alignItems="stretch"
            >
              {/* added prop for testing */}
              {props.closer && (
                ((leadManagementMenuValue && !leadManagementMenuLoading) || props.showMenu) &&
                <div className="lead-page__menu">
                  <LeadManagementMenu
                    completedSteps={completedSteps}
                    menuList={menu}
                    step={step}
                    onChange={goToStep}
                    eventId={props.leadId}
                    setData={setData}
                    setApiError={setApiError}
                    setSaveRequestText={setSaveRequestText}
                    getLeadData={getLeadData}
                    cancelStep={cancelStep}
                    completeStep={completeStep}
                  />
                </div>
              )}

              <div className="lead-page__step">{getStep()?.component}</div>
            </FlexContainer>
            <LeadActionButtonsStyles>
              <FlexContainer justifyContent="space-between" alignItems="center">
                <Notification
                  error={apiError}
                  text={saveRequestText}
                  setText={setSaveRequestText}
                  timeout={true}
                />
                <FlexContainer justifyContent="space-between">
                  <ButtonSecondary onClick={cancel}>Cancel</ButtonSecondary>

                  {
                    step === 2 ? 
                    <>
                      <ButtonPrimary style={{marginLeft: "8px"}}
                        disabled={!!errors.length || !areRequiredFieldsFilled(data, step) || (isSlotSelected === false)}
                        onClick={buttonAction}
                      >
                        {buttonLabel}
                      </ButtonPrimary>
                      {isCancelApt === false && <ButtonSecondary onClick={cancelApt} style={{margin: "8px"}}>Cancel Appointment</ButtonSecondary>}
                    </> : 
                      <ButtonPrimary
                        disabled={!!errors.length || !areRequiredFieldsFilled(data, step) || !!errorsAddons.length}
                        onClick={buttonAction}
                      >
                        {buttonLabel}
                      </ButtonPrimary>   
                  }
                </FlexContainer>
              </FlexContainer>
            </LeadActionButtonsStyles>
          </FlexContainer>
          <Notes />
          {(dispositionsValue && !dispositionsLoading) && <Dispositions />}
        </FlexContainer>
      </LeadManagementStyled>
    </>
  );
};

export default LeadManagement;
