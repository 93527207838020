import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const getWeekdayName = (dateString, timeZone = 'UTC') => {
    const date = new Date(dateString);

    const zonedDate = toZonedTime(date, timeZone);

    const weekdayName = format(zonedDate, 'EEE');
    
    return weekdayName;
};
