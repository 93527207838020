import React, { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import InputMask from "react-input-mask";
import { Tooltip } from "react-tooltip";

import { FormStyled } from "../LeadManagement.Styles";

import { FormField } from "./../../../components/Form/Input/Input.Styles";
import Subscription from "./../../../components/Form/Subscription/Subscription";
import Addons from "./../../../components/Form/Addons/Addons";
import PlanPrices from "./../../../components/Form/PlanPrices/PlanPrices";
import Payment from "./../../../components/Form/Payment/Payment";
import { BlockLabel, InputLabel, TextStyled } from "./../../../components/Text/Text.Styles";
import { FlexContainer } from "./../../../components/Flex/Flex.Styles";
import Select from "../../Form/Select/Select";
import { Lead } from "./../../../atoms/lead";
import { loadAutoComplete } from "../../Form/AutoComplete/autoCompleteLoader";
import { billingLoadAutoComplete } from "../../Form/AutoComplete/billingAutoCompleteLoader";
import RadioInput from "../../Form/RadioInput/RadioInput";
import ZipInput from "../../Form/Input/ZipInput";
import PhoneNumberInput from "../../Form/Input/PhoneNumberInput";
import { ScheduleAppointment } from "../SchedulingInfo/ScheduleAppointment";
import { ButtonNext, ButtonPrev } from "components/Buttons/Button.Styled";
import { baseBorder, graceGray600 } from "styleConstants";
import { BorderTextStyled } from "components/BorderText/BorderText.Styles";
import { getDayRange } from "utils/getDayRange";
import NextBillingDate from "components/Form/NextBillingDate/NextBillingDate";

export interface FormFieldType {
  isOverwritten?: boolean;
  label?: string;
  zipLabel?: string;
  component?: string;
  placeholder?: string;
  name: string;
  id?: string;
  additionalClassName?: string;
  autoComplete?: string;  
  display?: boolean;
  required?: boolean;
  regexp?: RegExp;
  validator?: RegExp;
  min?: Number;
  max?: Number;
  isMulti?: boolean;
  options?: {
    disabled?: boolean; additions?: Object[]; default?: boolean, value: any; label: string 
  }[];
  planId?: any;
  monthly?: boolean;
  mask?: string;
  onChange?: any;
  blockLabel?: string;
  selectName?: string;
  inputName?: string;
  disabled?: boolean;   
  defaultValue?: any;
  onSend?: any;
}

interface LeadInfoFormProps {
  setData: React.Dispatch<React.SetStateAction<Lead>>;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  data: any;
  errors: string[];
  formFields: FormFieldType[];
  onSend?: any;
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  loadingSpots?: boolean;
  eventId?: string;
  isAmSelected?: boolean;
  isPmSelected?: boolean;
  setIsAmSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPmSelected?: React.Dispatch<React.SetStateAction<boolean>>;
  isCancelApt?: boolean;
  step?: number;
  cancelApt?: any;
  isIniAptCmplted?: boolean;
  isServiceability?: boolean;
  onSlotSelect?: (isSelected: boolean, date: any, slot: Slot, addition: any) => void;
  selectedDate?: any;
  selectedSlot?: Slot;
  setAddonsErrors?: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface Slot {
  start: string;
  end: string;
}

const LeadInfoForm = (props: LeadInfoFormProps): React.JSX.Element => {
  const [scheduledDate, setScheduledDate] = useState();
  const [scheduledSlot, setScheduledSlot] = useState('');
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [initialTotal, setInitialTotal] = useState<Number>(0);
  const [recurringTotal, setRecurringTotal] = useState<Number>(0);
  const {page, setPage} = props;
  
  useEffect(() => {
    loadAutoComplete(props);
    billingLoadAutoComplete(props);
  }, [props.formFields.length, page]);

  useEffect(() => {
    setScheduledDate(props.data?.appointment?.appointment?.date);
  }, [props.data.appointment]);

  useEffect(() => {
    setScheduledDate(props.data?.appointment?.appointment?.date);
    setScheduledSlot(props.data?.appointment?.appointment?.start);
  }, [props.data.appointment]);
  
  const onChange = (
    value: any,
    label: string,
    regexp?: RegExp,
    validator?: RegExp,
    min?: Number,
    max?: Number,
  ) => {    
    const matches = regexp ? regexp.test(value) : true;

    if (matches || value === "") {
      props.setData((prev) => ({ ...prev, [label]: value }));
    }

    if (validator) {
      if (validator.test(value) || (label !== "phone" && value === "")) {
        props.setErrors((prev) =>
          prev.includes(label) ? [...prev.filter((i) => i !== label)] : prev,
        );
      } else {
        props.setErrors((prev) =>
          prev.includes(label) ? prev : [...prev, label],
        );
      }
    }

    if(min && max) {
      if(value >= min && value <= max) {
        props.setErrors((prev) =>
          prev.includes(label) ? [...prev.filter((i) => i !== label)] : prev,
        );        
      } else {
        props.setErrors((prev) =>
          prev.includes(label) ? prev : [...prev, label],
        );

        let newMessages = {...errorMessages};
        newMessages[label] = `Please enter a value above ${min} and below ${max}`;
        setErrorMessages(newMessages);      
      } 
    } else if(min) {
      if(value >= min) {
        props.setErrors((prev) =>
          prev.includes(label) ? [...prev.filter((i) => i !== label)] : prev,
        );        
      } else {
        props.setErrors((prev) =>
          prev.includes(label) ? prev : [...prev, label],
        );

        let newMessages = {...errorMessages};
        newMessages[label] = `Please enter a value above ${min}`;
        setErrorMessages(newMessages);      
      }      
    } else if(max) {
      if(value <= max) {
        props.setErrors((prev) =>
          prev.includes(label) ? [...prev.filter((i) => i !== label)] : prev,
        );
      } else {
        props.setErrors((prev) =>
          prev.includes(label) ? prev : [...prev, label],
        );
        
        let newMessages = {...errorMessages};
        newMessages[label] = `Please enter a value below ${max}`;
        setErrorMessages(newMessages);     
      }      
    }
  };
  
  const handlePrev = () => {
    setPage !== undefined && page != undefined && setPage(page - 1);
  };

  const handleNext = () => {
    setPage !== undefined && page != undefined && setPage(page + 1);
  };
  
  return (
    <>
      <FormStyled className="info-form" data-testid="lead-info-form">
        <FlexContainer justifyContent="start" alignItems="normal" style={{width: "-webkit-fill-available"}}>          
          {props.formFields.map((field, i) => {
            return (
              <FormField
                required={field.required}
                isInvalid={props.errors.includes(field.name)}
                className={`info-form__form-group ${field.additionalClassName}`}
                key={i}
              >
                {field.blockLabel && (
                  <BlockLabel className="info-form__form-group__block-label">
                    {field.blockLabel}
                  </BlockLabel>
                )}
                {!(field.component ==="dateInput" || field.component ==="timeSelect") && field.label ? <InputLabel>{field.label}</InputLabel> : null}
                {field.component === "debounceInput" && (
                  <DebounceInput
                    name={field.name}
                    debounceTimeout={700}
                    value={
                      props.data[
                        field.name as keyof typeof props.data
                      ] as string
                    }
                    onChange={(e) =>
                      onChange(
                        e.target.value,
                        field.name,
                        field.regexp,
                        field.validator,
                      )
                    }
                    placeholder={field.placeholder}
                    disabled={props.isIniAptCmplted}
                  />
                )}
                {field.component === "nextBillingDate" && (
                  <NextBillingDate
                    data={props.data}
                    setData={props.setData}
                    options={field.options}
                    isIniAptCmplted={props.isIniAptCmplted}
                  />
                )}
                {field.component === "input" && (       
                  <InputMask
                    data-tooltip-id={`${field.name}-error-tooltip`}
                    name={field.name}
                    id={field.id}
                    autoComplete={field.autoComplete}
                    value={
                      props.data[
                        field.name as keyof typeof props.data
                      ] as string
                    }
                    mask={field.mask || ''}
                    onChange={(e) =>
                      onChange(
                        e.target.value,
                        field.name,
                        field.regexp,
                        field.validator,
                        field.min,
                        field.max,
                      )
                    }
                    onFocus={(e) => field.isOverwritten ? e.target.select() : null}
                    placeholder={field.placeholder}
                    disabled={field.disabled || props.isIniAptCmplted}
                  />                             
                )}

                {field.component === "phoneInput" && (
                  <PhoneNumberInput
                    name={field.name}
                    value={
                      props.data[
                        field.name as keyof typeof props.data
                      ] as string
                    }
                    required={field.required}
                    onChange={(val) => onChange(val, field.name, field.regexp)}
                    placeholder={field.placeholder}
                    isIniAptCmplted={props.isIniAptCmplted}
                  />
                )}

                {field.component === "zipInput" && (
                  <>
                    <ZipInput
                      value={
                        props.data[
                          field.name as keyof typeof props.data
                        ] as string
                      }
                      onChange={(val) => onChange(val, field.name)}
                      placeholder={field.placeholder}
                      isServiceability={props.isServiceability}
                    />
                  </>
                )}

                {field.component === "dateInput" && (
                  <div>                    
                   
                    {props.loadingSpots ? <div>Loading Spots... </div> : 
                      
                      field.options?.length ? (
                        <>
                          <FlexContainer style={{"marginBottom": "10px"}} gap={10}>
                            <ButtonPrev sm onClick={handlePrev} disabled={props.page === 1} >{`<`}</ButtonPrev>
                            <BorderTextStyled fontSize="14" color={graceGray600} border={`1px solid ${baseBorder}`} padding="8px 32px" borderRadius="sm">{getDayRange(page).startDay}</BorderTextStyled>

                            <BorderTextStyled fontSize="14" color={graceGray600} border={`1px solid ${baseBorder}`} padding="8px 32px" borderRadius="sm">{getDayRange(page).lastDay}</BorderTextStyled>

                            <ButtonNext sm onClick={handleNext} disabled={props.page === 4}>{`>`}</ButtonNext>
                          </FlexContainer>
                          {props.data?.pr_appointment?.appointmentId && 
                            <FlexContainer style={{"marginTop": "-10px"}} gap={10}>
                                <TextStyled padding="10px" fontSize="lg" fontWeight="bold">Scheduled at {props.data?.pr_appointment.date} {props.data?.pr_appointment.start}{props.data?.appointment_selected_slot ? ` (${props.data.appointment_selected_slot})` : ''}</TextStyled>
                            </FlexContainer>
                          }
                          <FlexContainer justifyContent="center">
                            {
                              field.options?.map((date, index) => (
                                <ScheduleAppointment key={index} date={date} scheduledDate={scheduledDate} 
                                                    setScheduledDate={setScheduledDate} scheduledSlot={scheduledSlot} 
                                                    setScheduledSlot={setScheduledSlot} eventId={props.eventId} setData={props.setData} 
                                                    data={props.data} onSlotSelect={props?.onSlotSelect} selectedDate={props?.selectedDate}
                                                    selectedSlot={props?.selectedSlot || { start: '', end: ''}}/>
                              ))
                            }
                          </FlexContainer>
                        </>
                      ) : (
                        <TextStyled>No available Spots</TextStyled>
                      ) 
                    }       
                                
                  </div>
                )}

                {field.component === "subscription" && (
                  <Subscription
                    selectValue={
                      props.data[
                        field.selectName as keyof typeof props.data
                      ] as string
                    }
                    onSelectChange={(val) =>
                      field.selectName &&
                      onChange(val, field.selectName, field.regexp)
                    }
                    inputValue={
                      props.data[
                        field.inputName as keyof typeof props.data
                      ] as string
                    }
                    onInputChange={(val) =>
                      field.inputName &&
                      onChange(val, field.inputName, field.regexp)
                    }
                  />
                )}

                {field.component === "addons" && 
                  <>
                    <Addons
                      planId={field.planId || {}}
                      monthly={field.monthly}
                      data={props.data}
                      setData={props.setData}
                      setInitialTotal={setInitialTotal}
                      setRecurringTotal={setRecurringTotal}
                      isIniAptCmplted={props.isIniAptCmplted}
                      setAddonsErrors={props.setAddonsErrors}
                    />
                    <div>
                      <hr 
                        style={{
                          backgroundColor: "lightgray",
                          marginTop: "20px",
                          marginBottom: "10px",
                          border: "none",
                          height: "2px"
                        }} 
                      />

                      <div className="addons__total">
                        <TextStyled 
                          color={'#4B5563'} 
                          fontWeight="bold"
                          className={'price-total'}
                        >
                          INITIAL TOTAL
                        </TextStyled>
                        <TextStyled 
                          color={'#4B5563'} 
                          fontWeight="bold"
                          className={'price-total'}
                        >
                          ${initialTotal.toFixed(2)} USD
                        </TextStyled>                
                      </div>

                      <div className="addons__total">
                        <TextStyled 
                          color={'#4B5563'} 
                          fontWeight="bold"
                          className={'price-total'}
                        >            
                          {field.monthly ? 'MONTHLY' : 'RECURRING'} TOTAL
                        </TextStyled>
                        <TextStyled 
                          color={'#4B5563'} 
                          fontWeight="bold"
                          className={'price-total'}
                        >
                          ${recurringTotal.toFixed(2)} USD
                        </TextStyled>
                      </div>
                    </div>
                  </>
                }

                {field.component === "planPrices" && 
                  <PlanPrices 
                    planId={field.planId || {}}
                    monthly={field.monthly}
                  />
                }

                {field.component === "select" && (
                  <Select
                    isMulti={field.isMulti}
                    disabled={field.disabled || props.isIniAptCmplted}
                    options={field.options || []}
                    value={
                      props.data[
                        field.name as keyof typeof props.data
                      ] as any
                    }
                    onChange={(e) => onChange(e, field.name, field.regexp)}
                    placeholder={field.placeholder}
                  />
                )}
                {field.component === "payment" && field.options && (
                  <Payment onSend={(option: String) => props.onSend(option)} options={field.options} data={props.data} defaultValue={field?.defaultValue}/>
                )}
                {field.component === "radio" && field.options && (
                  <RadioInput 
                    name={field.name} 
                    options={field.options} 
                    onChange={(e) => {
                      field.onChange && field.onChange(e);
                      onChange(e, field.name, field.regexp);
                    }} 
                    defaultValue={field?.defaultValue}
                  />
                )}

                {props.errors.includes(field.name) &&
                  <Tooltip className={'error-tooltip'} id={`${field.name}-error-tooltip`} place="bottom" content={errorMessages[field.name]} isOpen={true}/>
                }              
              </FormField>
            );
          })}
        </FlexContainer>
      </FormStyled>
    </>
  );
};

export default LeadInfoForm;
