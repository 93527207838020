import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const getDayRange = (page, timeZone = 'UTC') => {
    const date = new Date();
    const zonedDate = toZonedTime(date, timeZone);
    
    const result = {
        startDay: new Date(zonedDate),
        lastDay: new Date(zonedDate)
    };

    // Find nearest Monday
    result.startDay.setDate(zonedDate.getDate() + (page - 1) * 5 + 1);
    const formattedStartDay = format(result.startDay, 'MMM dd');

    // Find nearest Saturday
    result.lastDay.setDate(zonedDate.getDate() + page * 5);
    const formattedLastDay = format(result.lastDay, 'MMM dd');

    return {
        startDay: formattedStartDay,
        lastDay: formattedLastDay
    };
};
