import styled from "@emotion/styled";
import {
  baseBorder,
  graceGray50,
  graceGray900,
  graceGray600,
  bluePrimary,
  baseWhite,
  bluePrimaryHover,
  gray400,
  uiGray50,
  pmSpotColor,
} from "styleConstants";

interface ButtonProps {
  sm?: boolean;
  isAmClicked?: boolean;
  isPmClicked?: boolean;
}

const buttonStylesDefault: Record<string, Record<string, string>> = {
  "border-radius": {
    sm: "4px",
    md: "4px",
  },
  padding: {
    sm: "8px 12px",
    md: "10px 20px",
  },
  "font-size": {
    sm: "12px",
    md: "14px",
  },
  "font-weight": {
    sm: "600",
    md: "500",
  },
  "background" : {
    am: bluePrimary,
    pm: pmSpotColor,
    default: baseWhite,
  },
  "color" : {
    am: baseWhite,
    pm: baseWhite,
    default: graceGray600,
  }
};

export const getButtonStylesDefault = (style: string, val: string) =>
  buttonStylesDefault[style][val];

const Button = styled.button<ButtonProps>`
  border-radius: ${(props) =>
    getButtonStylesDefault("border-radius", props.sm ? "sm" : "md")};
  padding: ${(props) =>
    getButtonStylesDefault("padding", props.sm ? "sm" : "md")};
  cursor: pointer;
  font-family: Inter;
  font-size: ${(props) =>
    getButtonStylesDefault("font-size", props.sm ? "sm" : "md")};
  font-style: normal;
  font-weight: ${(props) =>
    getButtonStylesDefault("font-weight", props.sm ? "sm" : "md")};
  line-height: 16px;
  transition: all ease-in-out 0.2s;
  text-wrap: nowrap;

  &:disabled {
    &,
    &:hover {
      background-color: ${graceGray50};
      color: ${gray400};
      cursor: default;
    }
  }
`;

export const ButtonSecondary = styled(Button)`
  background: ${baseWhite};
  color: ${graceGray600};
  border: 1px ${baseBorder} solid;

  &:hover {
    background: ${graceGray50};
    color: ${graceGray900};
  }
`;

export const ButtonPrimary = styled(Button)`
  background: ${bluePrimary};
  color: ${baseWhite};
  border: none;

  &:hover {
    background: ${bluePrimaryHover};
    color: white;
  }
`;

export const ButtonAmSpot = styled(Button)<{ isSelected: boolean }>`
  width: 100%;
  background: ${(props) =>
    getButtonStylesDefault("background", props.isSelected ? "am" : "default")};
  color: ${(props) =>
    getButtonStylesDefault("color", props.isSelected ? "am" : "default")};
  border: 2px ${bluePrimary} solid;
  padding: 5px 25px;
  font-size: 12px;
  line-height: 12px;

  &:hover {
    background: ${bluePrimaryHover};
    color: white;
  }
`;

export const ButtonPmSpot = styled(Button)`
  background: ${(props) =>
    getButtonStylesDefault("background", props.isPmClicked ? "pm" : "default")};
  color: ${(props) =>
    getButtonStylesDefault("color", props.isPmClicked ? "pm" : "default")};
  border: 2px ${pmSpotColor} solid;
  padding: 5px 25px;
  font-size: 12px;
  line-height: 12px;

  &:hover {
    background: ${pmSpotColor};
    color: white;
  }
`;

export const ButtonNaSpot = styled(Button)`
  width: 100%;
  background: ${baseWhite};
  color: ${graceGray600};
  border: 2px ${gray400} dotted;
  padding: 5px 25px;
  font-size: 12px;
  line-height: 12px;
  
  &:disabled {
    &,
    &:hover {
      background-color: ${graceGray50};
      color: ${gray400};
      cursor: default;
    }
  }
`;

export const ButtonPrev = styled(Button)`
  background: ${baseWhite};
  color: ${graceGray600};
  border: 1px ${baseBorder} solid;
  &:disabled {
    &,
    &:hover {
      background-color: ${graceGray50};
      color: ${gray400};
      cursor: default;
    }
  }
`;

export const ButtonNext = styled(Button)`
  background: ${baseWhite};
  color: ${graceGray600};
  border: 1px ${baseBorder} solid;
  &:disabled {
    &,
    &:hover {
      background-color: ${graceGray50};
      color: ${gray400};
      cursor: default;
    }
  }
`;
export const IconButtonStyles = styled(Button)`
  background: ${baseWhite};
  color: ${graceGray600};
  border: 1px ${baseBorder} solid;
  padding: 8px 12px;

  .icon-button-tooltip {
    background: ${uiGray50};
    color: ${graceGray900};
  }

  & > img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: ${graceGray50};
    color: ${graceGray900};
  }

  &:hover > img {
    filter: brightness(0.8);
  }

  .icon-button-tooltip {
    background: ${uiGray50};
    color: ${graceGray900};
  }
`;
